import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { get, isNumber } from 'lodash';
import { type Options } from 'pro-gallery-lib';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  getTPASettignsLiveSiteEditingDeprecated,
  isExperimentEnabled,
  isLayoutNameList,
  isLayoutNamePGSideBySide,
  isLayoutNameTextOnImage,
  resolveId,
  type LayoutName,
  type PostAction,
  type Section,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_FEED_MORE_ACTIONS_FIX } from '@wix/communities-blog-experiments';
import { TEXT_PLACEMENT, type TextBoxAlignment } from '../../constants/pro-gallery-options';
import useDeviceType from '../../hooks/use-device-type';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import usePostFontSize from '../../hooks/use-post-font-size';
import {
  getContentAlignment,
  getLayoutDescriptionLineCount,
  getLayoutTitleLineCount,
} from '../../selectors/app-settings-selectors';
import { getShouldUsePostListMobileTitleFontSize } from '../../selectors/mobile-settings-selectors';
import { getPostByIdOrSlug, getPostCoverImageSrc } from '../../selectors/post-selectors';
import { getContentAlignmentStyles } from '../../services/content-alignment-helpers';
import { getFeedColorClassName, type LayoutItemConfig } from '../../services/layout-config';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../store/post-ratings/post-ratings-selector';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import PostLink from '../link/post-link';
import { PostFooter } from '../post-footer';
import PostListItemCategoryLabel from '../post-list-item-category-label';
import PostListItemHeader from '../post-list-item-header';
import RatingsDisplay, { RatingsDisplayLayout } from '../ratings-display';
import { useSelector } from '../runtime-context';
import { HorizontalSeparatorForPostCard } from '../separator';
import { PostListItemProGalleryDescription } from './post-list-item-pro-gallery-description';
import PostListItemProGalleryTitle from './post-list-item-pro-gallery-title';

const empty = {};

type PostListItemProGalleryProps = {
  itemConfig: Partial<LayoutItemConfig>;
  type: LayoutName | undefined;
  postId: string | undefined;
  post: NormalizedPost | undefined;
  galleryOptions: Options;
  layoutSidesPadding: number;
  section: Section;
  canSeeMoreButton?: (post: NormalizedPost) => boolean;
  textBoxAlignment: TextBoxAlignment;
  visibleActions: PostAction[] | undefined;
};

const PostListItemProGallery: React.FC<PostListItemProGalleryProps> = React.memo(
  ({
    type,
    post: unsafePost,
    postId,
    itemConfig,
    canSeeMoreButton = () => false,
    layoutSidesPadding,
    galleryOptions = {},
    textBoxAlignment,
    visibleActions,
  }) => {
    const { isRTL } = useEnvironment();
    const { isMobile } = useDeviceType();
    const { applyFeedDesign, getPostClassName, section } = useIsFeedDesignEnabled();

    const post = useSelector((state) => unsafePost || getPostByIdOrSlug(state, postId)!);
    const { titleFontClassName, contentFontClassName } = useFontClassName();
    const { postTitleFontSize, postMetadataFontSize, defaultPostTitleFontSize } = usePostFontSize();
    const feedMetadataSettings = useFeedMetadataSettings({ post });
    const resolvedPostId = resolveId(post);

    const isLiveSiteEditingDeprecated = useSelector(getTPASettignsLiveSiteEditingDeprecated);
    const isFeedMoreActionsFixEnabled = useSelector((state) =>
      isExperimentEnabled(state, EXPERIMENT_FEED_MORE_ACTIONS_FIX),
    );

    const shouldUsePostListMobileTitleFontSize = useSelector(
      getShouldUsePostListMobileTitleFontSize,
    );
    const descriptionLineCount = useSelector((state) => getLayoutDescriptionLineCount({ state }));
    const titleLineCount = useSelector((state) => getLayoutTitleLineCount({ state }));
    const alignment = useSelector((state) => {
      const contentAlignment = getContentAlignment(state, isRTL);
      return getContentAlignmentStyles(contentAlignment, isRTL);
    });
    const postAverageRating = useSelector((state) => getPostAverageRating(state, resolvedPostId));
    const postTotalRatings = useSelector((state) => getPostTotalRatings(state, resolvedPostId));

    const isHoverDisabled =
      isLiveSiteEditingDeprecated && section === 'my-posts' && post?.status === 'unpublished';

    const showMoreButton = canSeeMoreButton(post);
    const { displayFooterIcons } = itemConfig;
    const lineCount =
      itemConfig.lineCount && 'description' in itemConfig.lineCount && itemConfig.lineCount
        ? itemConfig.lineCount
        : undefined;
    const hasIconsInHeader = showMoreButton || post?.isPinned;
    const layoutList = isLayoutNameList(type);
    const isSideBySideLayout = isLayoutNamePGSideBySide(type);
    const withoutFooter = !feedMetadataSettings.isMetadataFooterVisible;
    const withDescription = feedMetadataSettings.showPostDescription && !layoutList;
    const layoutTypeClassName = type ? styles[type] : undefined;
    const containerClassName = useMemo(
      () =>
        classNames(
          styles.container,
          styles.proGallery,
          isRTL && styles.isRTL,
          layoutTypeClassName,
          alignmentStyles[alignment],
          withoutFooter && styles.withoutFooter,
          contentFontClassName,
          'blog-text-color',
          'blog-card-background-color',
          'blog-card-border-color',
          'post-list-item',
          getPostClassName(
            'border-color',
            'post-container',
            getFeedColorClassName(type, 'background-color'),
          ),
        ),
      [
        isRTL,
        layoutTypeClassName,
        alignment,
        withoutFooter,
        contentFontClassName,
        getPostClassName,
        type,
      ],
    );

    const contentWrapperClassName = useMemo(
      () =>
        classNames(
          styles.contentWrapper,
          layoutTypeClassName,
          isRTL && alignmentStyles.isRTL,
          !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
          withoutFooter && styles.withoutFooter,
          'post-list-item-wrapper',
          isLayoutNameTextOnImage(type) && getPostClassName('overlay-background-color'),
          getPostClassName(
            'description-font',
            getFeedColorClassName(type, 'description-color'),
            getFeedColorClassName(type, 'description-fill'),
          ),
        ),
      [isRTL, layoutTypeClassName, applyFeedDesign, withoutFooter, getPostClassName, type],
    );

    const titleContainer = useMemo(
      () =>
        classNames(
          styles.textWrapper,
          {
            [getPostClassName('link-hashtag-hover-color').join(' ')]: !isHoverDisabled,
            [styles.fadeout]: descriptionLineCount || titleLineCount,
          },
          layoutTypeClassName,
          feedMetadataSettings.showCategoryLabel && styles.withCategoryLabel,
        ),
      [
        isHoverDisabled,
        descriptionLineCount,
        titleLineCount,
        layoutTypeClassName,
        feedMetadataSettings.showCategoryLabel,
        getPostClassName,
      ],
    );

    const titleLinkClassName = useMemo(
      () =>
        classNames(
          styles.titleWrapper,
          styles.proGallery,
          layoutTypeClassName,
          showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
        ),
      [layoutTypeClassName, showMoreButton],
    );

    const titleContainerClassName = useMemo(
      () =>
        classNames(
          styles.title,
          titleFontClassName,
          layoutTypeClassName,
          withoutFooter && styles.withoutFooter,
          !withDescription && withoutFooter && styles.withoutBottomMargin,
          !withDescription && styles.withoutDescription,
          post?.isPinned && styles.withIcons,
          showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
        ),
      [
        titleFontClassName,
        layoutTypeClassName,
        withoutFooter,
        withDescription,
        post?.isPinned,
        showMoreButton,
      ],
    );

    const headerClassName = useMemo(
      () =>
        classNames(
          styles.headerWrapper,
          contentFontClassName,
          getPostClassName('description-font'),
          alignmentStyles.headerContainer,
        ),
      [contentFontClassName, getPostClassName],
    );

    const footerClassName = useMemo(
      () =>
        classNames(
          getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
        ),
      [getPostClassName, type],
    );

    const styleFontSizeTitle = useMemo(
      () => ({
        fontSize: shouldUsePostListMobileTitleFontSize
          ? postTitleFontSize
          : defaultPostTitleFontSize,
      }),
      [shouldUsePostListMobileTitleFontSize, postTitleFontSize, defaultPostTitleFontSize],
    );

    const styleFontSizeMetadata = useMemo(
      () => ({
        fontSize: postMetadataFontSize,
      }),
      [postMetadataFontSize],
    );

    const styleContainer = useMemo(() => {
      const style: React.CSSProperties = {
        background:
          (getPostCoverImageSrc(post) || post?.media?.embedMedia?.thumbnail?.url) &&
          isLayoutNameTextOnImage(type)
            ? 'transparent'
            : undefined,
        borderWidth: 0,
        '--wix-blog-inline-padding': isNumber(layoutSidesPadding)
          ? `${layoutSidesPadding}px`
          : undefined,
      };

      if (layoutList) {
        style.background = 'transparent';
        style.paddingTop = '12px';
        style.paddingBottom = '12px';

        if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT) {
          style.marginLeft = '14px';
        } else {
          style.marginRight = '14px';
        }
      }

      // HACK: for side-by-side with borders and no image
      if (!get(post, 'coverImage.shouldRender') && isSideBySideLayout) {
        if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT) {
          style.paddingLeft = `${galleryOptions.itemBorderWidth * 2}px`;
        }
        if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_LEFT) {
          style.paddingRight = `${galleryOptions.itemBorderWidth * 2}px`;
        }
      }

      return style;
    }, [
      post,
      type,
      layoutSidesPadding,
      layoutList,
      textBoxAlignment,
      galleryOptions.itemBorderWidth,
      isSideBySideLayout,
    ]);

    const getLayoutPaddingStyles = useCallback(
      (isFooter = false): React.CSSProperties => {
        if (!isLayoutNameTextOnImage(type) && isFooter) {
          return empty;
        }

        return isNumber(layoutSidesPadding)
          ? {
              paddingLeft: layoutSidesPadding,
              paddingRight: layoutSidesPadding,
              ...(isFooter && {
                left: 0,
                right: 0,
              }),
            }
          : {};
      },
      [layoutSidesPadding, type],
    );

    const header = useMemo(
      () => (
        <PostListItemHeader
          className={headerClassName}
          post={post}
          showMoreButton={showMoreButton}
          showProfileImage={!isLayoutNameTextOnImage(type)}
          style={styleFontSizeMetadata}
          type={type}
          visibleActions={visibleActions}
        />
      ),
      [headerClassName, post, showMoreButton, type, styleFontSizeMetadata, visibleActions],
    );

    const renderRatingsDisplay = useCallback(
      () =>
        feedMetadataSettings.showPostRating && postTotalRatings ? (
          <RatingsDisplay
            className={classNames(
              styles.rating,
              styles[section],
              type ? styles[type] : undefined,
              alignmentStyles.rating,
              {
                [styles.withoutFooter]: withoutFooter,
                [styles.withTextOnImageStyles]: isLayoutNameTextOnImage(type),
              },
            )}
            rating={postAverageRating}
            count={postTotalRatings}
            layout={isMobile ? RatingsDisplayLayout.with_range : RatingsDisplayLayout.count_only}
          />
        ) : null,
      [
        feedMetadataSettings.showPostRating,
        postTotalRatings,
        postAverageRating,
        section,
        type,
        withoutFooter,
        isMobile,
      ],
    );

    const renderCategoryLabel = useCallback(
      () =>
        feedMetadataSettings.showCategoryLabel && (
          <PostListItemCategoryLabel
            className={classNames(alignmentStyles.categoryLabel, styles.categoryLabelWrapper)}
            post={post}
            postListLayout={type}
          />
        ),
      [feedMetadataSettings.showCategoryLabel, post, type],
    );

    const Title = useMemo(
      () => () =>
        (
          <div
            style={styleFontSizeTitle}
            className={classNames(titleContainerClassName, alignmentStyles.textAlign)}
            data-hook="post-list-item__title"
          >
            <PostListItemProGalleryTitle
              type={type}
              title={post?.title}
              style={styleFontSizeTitle}
              lineCount={lineCount?.title}
              titleLineCount={titleLineCount}
              showCategoryLabel={feedMetadataSettings.showCategoryLabel}
              isHoverDisabled={isHoverDisabled}
            />
            {!layoutList && renderRatingsDisplay()}
          </div>
        ),
      [
        styleFontSizeTitle,
        titleContainerClassName,
        type,
        post?.title,
        lineCount?.title,
        titleLineCount,
        feedMetadataSettings.showCategoryLabel,
        isHoverDisabled,
        layoutList,
        renderRatingsDisplay,
      ],
    );

    return (
      <div
        className={classNames(containerClassName, styles.forceRelative)}
        style={styleContainer}
        data-hook="post-list-item"
      >
        <div style={getLayoutPaddingStyles()} className={contentWrapperClassName}>
          {!layoutList &&
            ((isFeedMoreActionsFixEnabled && hasIconsInHeader) ||
              feedMetadataSettings.isMetadataHeaderVisible) &&
            header}
          {!layoutList && renderCategoryLabel()}
          <div className={titleContainer}>
            {isHoverDisabled ? (
              <Title />
            ) : (
              <PostLink
                postLink={isHoverDisabled ? undefined : post?.link}
                postPath={post?.slug!}
                className={titleLinkClassName}
              >
                <Title />
              </PostLink>
            )}
            <PostListItemProGalleryDescription
              post={post}
              lineCount={lineCount}
              type={type}
              showMoreButton={showMoreButton}
              withoutFooter={withoutFooter}
              descriptionLineCount={descriptionLineCount}
              isHoverDisabled={isHoverDisabled}
              withDescription={withDescription}
            />
          </div>
          {layoutList && renderCategoryLabel()}
          {layoutList && renderRatingsDisplay()}
          {layoutList && header}
          {feedMetadataSettings.isMetadataFooterVisible && (
            <div
              style={getLayoutPaddingStyles(true)}
              className={classNames(
                styles.footer,
                layoutTypeClassName,
                styles.withoutCover,
                styles[section],
              )}
            >
              {!layoutList && (
                <HorizontalSeparatorForPostCard
                  className={classNames(styles.separator, layoutTypeClassName)}
                />
              )}
              <div style={styleFontSizeMetadata}>
                <PostFooter
                  className={footerClassName}
                  displayIcons={displayFooterIcons}
                  post={post}
                  type={type}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default PostListItemProGallery;
